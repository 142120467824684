import React, { useEffect, useRef, useState } from 'react'

import { ClerkChatContext, ClerkLikeChatState } from './ClerkLikeChatContext'
import styles from './ClerkLikeChat.module.scss'
import Card from './ClerkLikeChatCard'
import Bubble from './ClerkLikeChatBubble'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import useExitIntent from '../../hooks/useExitIntent'

export default function ClerkLikeChat() {
  const [chatState, setChatState] = useState<ClerkLikeChatState>('CLOSED')
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    document.body.classList.add('hide-hubspot-chat')

    return () => {
      document.body.classList.remove('hide-hubspot-chat')
    }
  }, [])

  const open = () => {
    setChatState('FULL_OPEN')
  }

  const close = () => {
    setChatState('CLOSED')
  }

  const toggle = () => {
    setChatState((prev) => {
      switch (prev) {
        case 'CLOSED':
          return 'FULL_OPEN'
        case 'SEMI_OPEN':
          return 'FULL_OPEN'
        case 'FULL_OPEN':
          return 'CLOSED'
      }
    })
  }

  useOnClickOutside(ref, () => {
    if (chatState === 'CLOSED') return
    close()
  })

  useExitIntent(() => {
    setChatState('SEMI_OPEN')
  })

  return (
    <ClerkChatContext.Provider value={{ chatState, setChatState, open, close, toggle }}>
      <div ref={ref} className={styles.root}>
        <Card />
        <Bubble />
      </div>
    </ClerkChatContext.Provider>
  )
}
