import React, { FC, SVGProps } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import classNames from 'classnames'
import { navigate } from 'gatsby'
import { useLocation } from '@reach/router'

import { useChatContext } from './ClerkLikeChatContext'
import styles from './ClerkLikeChatCard.module.scss'
import { PATH, URL } from '../../constants/content'
import Button from '../common/Button'
import { ReactComponent as DocsSvg } from './images/docs.svg'
import { ReactComponent as SalesSvg } from './images/sales.svg'
import { ReactComponent as SupportSvg } from './images/support.svg'
import { ReactComponent as DiscordSvg } from './images/discord.svg'
import { isLocalLink } from '../../helpers/url'
import { useAmpli } from '../../helpers/amplitude'

const containerVariants = {
  hidden: { opacity: 0, y: 10 },
  visible: { opacity: 1, y: 0 },
}

const menuVariants = {
  hidden: { opacity: 0, y: 5, height: 0 },
  visible: { opacity: 1, y: 0, height: 'auto' },
}

export default function ClerkLikeChatCard() {
  const { chatState, open } = useChatContext()

  return (
    <AnimatePresence>
      {['FULL_OPEN', 'SEMI_OPEN'].includes(chatState) && (
        <motion.div
          variants={containerVariants}
          initial='hidden'
          exit='hidden'
          animate='visible'
          transition={{ duration: 0.275, ease: 'easeInOut' }}
          className={styles.root}
        >
          <div className={styles.inner} role='button' onClick={open}>
            <header className={classNames(styles.header, chatState === 'SEMI_OPEN' && styles.clickable)}>
              <h5 className={styles.title}>
                <span className={styles.wave}>👋</span> Hi there! Need help?
              </h5>
              <p className={styles.description}>Get help with using Fingerprint.</p>
            </header>
            <AnimatePresence>
              {chatState === 'FULL_OPEN' && (
                <motion.div variants={menuVariants} initial='hidden' exit='hidden' animate='visible'>
                  <div className={styles.menu}>
                    <MenuItem Icon={DocsSvg} href={URL.docsUrl}>
                      Read documentation
                    </MenuItem>
                    <MenuItem Icon={SalesSvg} href={PATH.contactSales}>
                      Talk to sales
                    </MenuItem>
                    <MenuItem Icon={SupportSvg} href={PATH.support}>
                      Contact support
                    </MenuItem>
                  </div>
                  <Button
                    openNewTab
                    variant='tertiary'
                    size='small'
                    className={styles.button}
                    href={URL.discordServerURL}
                  >
                    <DiscordSvg />
                    Join Discord
                  </Button>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

type MenuItemProps = {
  Icon?: FC<SVGProps<SVGSVGElement>>
  href: string
  children: string
}

function MenuItem({ children, Icon, href }: MenuItemProps) {
  const { pathname } = useLocation()
  const { ampli, botDetected } = useAmpli()

  const trackCta = (ctaName: string, href: string) => {
    ampli.webCtaClicked({ ctaName: `Clerk-like chat | ${ctaName}`, 'Web Page Path': pathname, botDetected })
    return isLocalLink(href) ? navigate(href) : window.open(href, '_blank')
  }

  return (
    <button className={styles.item} onClick={() => trackCta(children, href)}>
      {Icon && <Icon />}
      <span>{children}</span>
    </button>
  )
}
