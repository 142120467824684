import React from 'react'
import classNames from 'classnames'

import { ReactComponent as FingerprintSvg } from './images/fingerprint.svg'
import styles from './ClerkLikeChatBubble.module.scss'
import { useChatContext } from './ClerkLikeChatContext'

export default function ClerkLikeChatBubble() {
  const { chatState, toggle } = useChatContext()

  return (
    <button className={styles.root} onClick={toggle}>
      <div className={styles.inner}>
        <FingerprintSvg
          className={classNames(styles.fingerprint, chatState === 'CLOSED' ? styles.isClosed : styles.isOpen)}
        />
      </div>
    </button>
  )
}
