import { useEffect, useState } from 'react'

export default function useExitIntent(onExitIntent: () => void, threshold = 5) {
  const [hasInteracted, setHasInteracted] = useState(false)

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      if (!hasInteracted) {
        setHasInteracted(true)
        return
      }

      if (event.clientY <= threshold) {
        onExitIntent()
      }
    }

    window.addEventListener('mousemove', handleMouseMove)

    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
    }
  }, [onExitIntent, threshold, hasInteracted])
}
