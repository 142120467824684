import React, { createContext, useContext, useEffect, useState, useCallback } from 'react'
import { AMPLITUDE_API_KEY, IS_VERCEL } from '../constants/env'
import { BASE_URL } from '../constants/content'
import { ampli, Ampli, Event } from '../ampli'
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react'
import { useSmartSignalsContext } from '../context/BotdContext'
import { getConfig } from './fpjs'

interface AmpliContextValue {
  ampli: Ampli
  isClientLoaded: boolean
  botDetected: string
}

const AmpliContext = createContext<AmpliContextValue>({
  ampli: ampli,
  isClientLoaded: false,
  botDetected: 'False',
})

export enum ExperimentValue {
  Control = 'control',
  Treatment = 'treatment',
}

export enum ExperimentFlags {
  NewContactSalesUi = 'q4-2024-new-contact-sales-ui',
  ContactSupportWithInkeepAi = 'q4-2024-contact-support-inkeep-ask-ai',
}

export enum LocalExperimentFlags {}

export const getValidExperimentValue = (value?: string): ExperimentValue => {
  switch (value) {
    case ExperimentValue.Treatment:
      return ExperimentValue.Treatment
    default:
      return ExperimentValue.Control
  }
}

const EVENT_TYPE = 'Web Page Viewed'

/**
 * This is a plugin that renames the Page view event_properties according to our analytics needs
 * @returns EnrichmentPlugin
 */

const renameEventPropertiesEnrichment = (botDetected: string) => ({
  name: 'rename-event-properties-enrichment',
  type: 'enrichment' as const,
  async setup() {
    return
  },
  async execute(event: Event) {
    // Only apply to Web Page View events
    if (event.event_type !== EVENT_TYPE) {
      return event
    }

    // Rename event properties and add botDetected
    const originalEventProperties = event.event_properties
    originalEventProperties &&
      (event.event_properties = {
        'Web Page Domain': originalEventProperties['[Amplitude] Page Domain'],
        'Web Page Location': originalEventProperties['[Amplitude] Page Location'],
        'Web Page Path': originalEventProperties['[Amplitude] Page Path'],
        'Web Page Title': originalEventProperties['[Amplitude] Page Title'],
        'Web Page URL': originalEventProperties['[Amplitude] Page URL'],
        botDetected: botDetected,
      })

    return event
  },
})

export const AmpliProvider = ({ children }: { children: React.ReactNode }) => {
  const { data } = useVisitorData(getConfig)
  const visitorId = data?.visitorId
  const { visitorData } = useSmartSignalsContext()
  const [botDetected, setBotDetected] = useState<string>('False')
  const [isClientLoaded, setIsClientLoaded] = useState(false)

  const setUserData = useCallback(
    (currentVisitorId?: string) => {
      if (!isClientLoaded || !ampli.client || !currentVisitorId) {
        return
      }
      ampli.client.setDeviceId(currentVisitorId)
      ampli.client.setGroup('fingerprint-device-id', currentVisitorId)
    },
    [isClientLoaded]
  )

  useEffect(() => {
    if (!visitorId || !visitorData?.products.botd.data?.bot?.result) return // Don't initialize if visitorId is not available

    ampli
      .load({
        client: {
          apiKey: AMPLITUDE_API_KEY,
          configuration: {
            serverUrl: IS_VERCEL ? `${BASE_URL}/Vtu1bhY5s/` : undefined,
            deviceId: visitorId,
            identityStorage: 'localStorage',
            defaultTracking: {
              attribution: true,
              pageViews: {
                eventType: EVENT_TYPE,
                trackOn: () => {
                  // Only letters, numbers and dashes are allowed in the pathname
                  return /^[a-zA-Z0-9/-]*$/.test(window.location.pathname)
                },
              },
              sessions: false,
              formInteractions: false,
              fileDownloads: false,
            },
          },
        },
      })
      .promise.then(() => {
        const botDetectedData = visitorData?.products.botd.data?.bot?.result === 'bad' ? 'True' : 'False'
        ampli.client.add(renameEventPropertiesEnrichment(botDetectedData))
        setIsClientLoaded(true)
      })
  }, [visitorId, visitorData])

  useEffect(() => {
    if (isClientLoaded && visitorId) {
      setUserData(visitorId)
    }
  }, [visitorId, setUserData, isClientLoaded])

  useEffect(() => {
    if (isClientLoaded && visitorData?.products.botd.data?.bot?.result) {
      const botDetected = visitorData.products.botd.data?.bot?.result === 'bad' ? 'True' : 'False'
      setBotDetected(botDetected)
      ampli.identify(undefined, { botDetected })
    }
  }, [isClientLoaded, visitorData])

  return <AmpliContext.Provider value={{ ampli, isClientLoaded, botDetected }}>{children}</AmpliContext.Provider>
}

export const useAmpli = () => useContext(AmpliContext)
