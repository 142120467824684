import { createContext, Dispatch, SetStateAction, useContext } from 'react'

export type ClerkLikeChatState = 'CLOSED' | 'SEMI_OPEN' | 'FULL_OPEN'

type ClerkChatContextValue = {
  chatState: ClerkLikeChatState
  setChatState: Dispatch<SetStateAction<ClerkLikeChatState>>
  open: () => void
  close: () => void
  toggle: () => void
}

export const ClerkChatContext = createContext({} as ClerkChatContextValue)

export const useChatContext = () => {
  const ctx = useContext(ClerkChatContext)

  if (!ctx) {
    throw new Error('useChatContext must be used within a ClerkChatContext Provider')
  }

  return ctx
}
