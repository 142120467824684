import './src/styles/vendors/normalize.scss'
import './src/styles/vendors/tippy.scss'
import './src/styles/vendors/swiper.scss'
import './src/styles/vendors/code-theme.scss'
import './src/styles/global-styles.scss'
import './src/styles/custom-properties.scss'

import React from 'react'
import AppProviders from './src/AppProviders'
import { consolePromotionMessage } from './src/helpers/consolePromotionMessage'
import { URL } from './src/constants/content'
import { navigate } from 'gatsby'

let userCountry = null

export const onClientEntry = async () => {
  try {
    // Fetch the user's country from the serverless function
    const response = await fetch('/api/get-country')
    const data = await response.json()
    userCountry = data.country
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error fetching country data:', error)
  }
}

export const wrapPageElement = ({ element }) => <AppProviders>{element}</AppProviders>

// Mitigate flickering on blog post
export const onPreRouteUpdate = ({ location }) => {
  // Check if the user is from Brazil and visiting the specific blog post
  if (userCountry === 'BR' && location.pathname === '/blog/geolocation-brazil-gambling-regulations/') {
    // Redirect to the Brazilian version of the blog post using Gatsby's navigate
    navigate('/blog/geolocation-brazil-gambling-regulations-br/', { replace: true })
  }
}

export const onInitialClientRender = () => {
  consolePromotionMessage(`Like breaking things to see how they work? Join us: ${URL.careersConsoleLogUrl}`)
  consolePromotionMessage(`Have a great day! 🫶🏻`)
}
